// @ts-nocheck
import { evaluate } from 'mathjs';
import { isEmpty } from '../../utils/helper/helper';
import type { UiSetupConfig, Variable, Dependency } from './types';

/**
 * Returns an array of variables that belong to a specific subMenu
 * @param uiSetupConfig uiSetupConfig
 * @param lev1 level1 name
 * @param lev2 level2 name
 * @returns {*}
 */
export const getVariablesOfSubMenu = (
  uiSetupConfig: UiSetupConfig,
  lev1: string,
  lev2: string
): { alias: string; name: string; inputType: string; unit: string; dependency: Dependency; hidden?: boolean }[] => {
  const discardedVariable = {
    alias: 'Discarded Variable',
    name: 'discarded_varibale',
    unit: '-',
  };
  if (isEmpty(uiSetupConfig)) {
    return [discardedVariable];
  }

  const le1 = uiSetupConfig.find((el) => el.name === lev1);
  const subMenu = le1
    ? le1.subMenu
    : [
        {
          name: 'SubMenu',
          variables: [
            {
              alias: 'Variable',
              name: 'Variable',
              inputType: 'number',
              unit: '-',
            },
          ],
        },
      ];
  const le2 = subMenu?.find((el) => el.name === lev2);
  return le2 ? le2.variables : [discardedVariable];
};

/**
 * Get unit of variable
 * @param variableName unit of this variable gets returned
 * @returns {*}
 */
export const getVariableUnit = (variableName: string, variables: Array<Variable>) => {
  let unit = '-';

  if (isEmpty(variables)) {
    return unit;
  }

  const variable = variables.find((el) => el.name === variableName);

  if (variable) {
    unit = variable.displayUnit && variable.displayUnit !== 'null' ? variable.displayUnit : variable.unit;
    unit = unit === '1' ? '-' : unit;
  }

  return unit;
};

/**
 * @description it evaluates the dependency property of each variable
 * and returns the relevant boolean for conditional rendering
 * @param dependency object with name, type and value
 */
export const checkVariableDependency = (values: any, dependency?: Dependency) => {
  if (dependency) {
    if (dependency.type === 'hideValue') {
      if (values[dependency.name].toString() === dependency.value.toString()) return false;
    }

    if (dependency.type === 'showValue') {
      if (values[dependency.name].toString() !== dependency.value.toString()) return false;
    }
  }
  return true;
};

/**
 * @description This function maps the FMU parameter with UI parameter and
 * takes the information from structure json file. It also adds 'hidden' parameters,
 * but not stopt_time and output_interval
 * @param values from Formik
 * @param uiVariablesList list of variables from structure json file
 * @returns {*}
 */
export const getMappedFmuUiValues = (values: any, uiVariablesList: Array<Variable>) => {
  const mappedFmuInputs = {};
  const fmuInputsList = uiVariablesList.filter((el) => el.uiType === 'fmuInput');
  fmuInputsList.forEach((input) => {
    mappedFmuInputs[input.name] = values[input.name];
  });

  return {
    inputsFMU: mappedFmuInputs,
  };
};

export const getVariableList = (uiSetupConfigComplete: UiSetupConfig, fmuComponents: Array<any> = []) =>
  uiSetupConfigComplete
    .flatMap((menu) => menu.subMenu)
    .flatMap((subMenu) =>
      subMenu?.variables.flatMap((variable) => {
        if (variable.inputType === 'ComponentSelection') {
          const componentSelectionVar = [];
          const fmuComponent = fmuComponents?.find((c) => c.name === variable.fmuComponentName);

          if (fmuComponent) {
            const parameterDefinitions = fmuComponent.component.merged_parameter_definitions;
            Object.keys(parameterDefinitions).forEach((parameterName) => {
              let defaultInputType;
              if (!parameterDefinitions[parameterName]?.inputType) {
                switch (parameterDefinitions[parameterName].type) {
                  case 'Real':
                  case 'Integer':
                  case 'Number':
                    defaultInputType = 'ButtonField';
                    break;
                  case 'Boolean':
                    defaultInputType = 'Checkbox';
                    break;
                  case 'String':
                    defaultInputType = 'TextField';
                    break;
                  case 'Array':
                    defaultInputType = 'InputTable';
                    break;
                  case 'Enumeration':
                    defaultInputType = 'SelectField';
                    break;
                }
              }
              componentSelectionVar.push({
                ...parameterDefinitions[parameterName],
                inputType: parameterDefinitions[parameterName]?.inputType ?? defaultInputType,
                name: `${variable.fmuComponentName}:${parameterName}`,
              });
            });
          }

          // Pushing the component selection variable at the end of the list, makes sure that when setting initial
          // values, the parameters' start values are overwritten by the default component instance selection parameters
          componentSelectionVar.push(variable);
          return componentSelectionVar;
        }

        return [variable];
      })
    );

export const getInitialValues = (
  uiSetupConfigComplete: UiSetupConfig,
  mainMenuItems: Array<string>,
  fmuComponents: Array<any>
) => {
  const initValues = {};
  // filter uiStructureArr with mainMenuItems
  const uiStructureFiltered = uiSetupConfigComplete.filter((j) => mainMenuItems.includes(j.name));
  // get a flat variable list
  const variableList = getVariableList(uiStructureFiltered, fmuComponents);

  // go through variables list and write initial values for Formik
  variableList.forEach((v: Variable) => {
    if (v.inputType === 'variableInput') {
      if (v.variableInput) {
        if (v.variableInput.start) {
          const startValues = [];
          v.variableInput.start.forEach((el, indStartValues) => {
            const rowValues = {
              id: indStartValues,
            };
            v.variableInput.columnNames.forEach((name, indColumnName) => {
              rowValues[name] = el[indColumnName];
            });
            startValues.push(rowValues);
          });
          initValues[v.name] = startValues;
        }
      }
    } else if (['Table', 'InputTable'].includes(v.inputType)) {
      initValues[v.name] = v.arrays.start;
      initValues[`${v.name}_dataSource`] = v.dataSource;
    } else if (v.inputType === 'ComponentSelection') {
      const fmuComponent = fmuComponents?.find((c) => c.name === v.fmuComponentName);
      const defaultInstance = fmuComponent?.default_instance || fmuComponent?.instances[0].id;
      initValues[v.name] = defaultInstance;
      if (fmuComponent) {
        const params = fmuComponent.instances.find((i) => i.id === defaultInstance).parameters;
        for (const [key, value] of Object.entries(params)) {
          initValues[`${v.fmuComponentName}:${key}`] = value;
        }
      }
    } else if (v.inputType === 'InactiveCalc') {
      const calcVariables = Object.entries(v.calculation.variables).map((entry) => {
        const [formuleName, variableName] = entry;
        return {
          formuleName,
          variableName,
        };
      });
      const scope = {};
      calcVariables.forEach((calcVar) => {
        const variableValue = variableList.find((variable) => variable.name === calcVar.variableName);
        scope[calcVar.formuleName] = variableValue.start;
        return 0;
      });
      initValues[v.name] = evaluate(v.calculation.formula, scope);
    } else if (v.inputType !== 'Header') {
      // make sure that 'Header' are not added to initialValues
      initValues[v.name] = v.start;
    }
  });
  return initValues;
};

export default getVariablesOfSubMenu;
