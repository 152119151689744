// @ts-nocheck
/**
 * This file contains app wide helper functions.
 */
import { RouterProps } from 'react-router-dom';
import _ from 'lodash';
import httpService from '../../services/http';
import i18n from '../../assets/i18n/i18n';
import { unitConversion } from './units';

/**
 * @description get attribute of object safe or undefined.
 * example useage: get(() => this.props.foo.bar.awesome)
 * if foo or bar do not exist, the javascript code will not crash. We will only get undefined.
 * see: https://silvantroxler.ch/2017/avoid-cannot-read-property-of-undefined/
 * @param fn
 * @return {*}
 */
export const get = (fn) => {
  try {
    return fn();
  } catch (e) {
    return undefined;
  }
};

/**
 * This function transfers error messages we receive from the api to the format of formik. Formik's error property
 * simply holds an object with keys equal to the names of the fields, e.g. {email: 'wrong', password:'big error'}.
 * If for some reason our api returns errors with different names, we can make a mapping here.
 * Currently we simply rename the non_field_errors property, because the python naming convention looks so ugly in JS.
 * And we also translate all fields of the error response to the currently selected language
 * @param data
 * @returns {{nonFieldErrors: *}}
 */
export const transformApiErrors = (data) => {
  return { ...data, nonFieldErrors: get(() => data.non_field_errors) };
};

/**
 * This function receives data from an api error response and a i18n instance t as parameters.
 * t is then used to translate all fields of the error response data, which is then returned.
 * @param data api error response data object
 * @param t i18n instance
 * @returns {translatedData}
 */
export const translateApiErrors = (data) => {
  const translatedData = { ...data };
  Object.keys(data).forEach((key) => {
    if (Array.isArray(translatedData[key])) {
      translatedData[key] = translatedData[key].map((s) =>
        i18n.t(s, {
          keySeparator: false,
        })
      );
    } else {
      translatedData[key] = i18n.t(translatedData[key], {
        keySeparator: false,
      });
    }
  });
  return translatedData;
};

/**
 * Removes the authToken and redirects to welcome page.
 * @param history browser history from react router
 */
export const handleLogoutClick = (history: RouterProps['history']) => {
  httpService.removeAuthToken();
  sessionStorage.removeItem('userId');
  history.push('/');
};

/**
 * Creates a date in local format
 * @param dateString date in string format
 * @returns dateLocal
 */
export const changeDateToLocalTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: 'short',
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const dateLocal = date.toLocaleString('de-DE', options);
  return dateLocal;
};

/**
 * Creates a date in local format
 * @param dateString date in string format
 * @returns dateLocal
 */
export const changeDateToLocal = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const dateLocal = date.toLocaleString('de-DE', options);
  return dateLocal;
};

/**
 * Convert milliseconds to h:m:s format
 * @param ms time duration in milliseconds
 * @returns h + ':' + m + ':' + s
 */
export const convertMStoHMS = (ms) => {
  let h;
  let m;
  let s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s %= 60;
  h = Math.floor(m / 60);
  m %= 60;
  const d = Math.floor(h / 24);
  h %= 24;
  h += d * 24;
  return `${h}:${m}:${s}`;
};

/**
 * Convert milliseconds to days format
 * @param ms time duration in milliseconds
 * @returns d
 */
export const convertMStoD = (ms) => {
  let h;
  let m;
  let s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s %= 60;
  h = Math.floor(m / 60);
  m %= 60;
  const d = Math.floor(h / 24);
  h %= 24;
  h += d * 24;
  return `${d}`;
};

/**
 * @description sorts data by key in decreasing order (Z to A)
 * @param data array of objects that shall be sorted
 * @param key string, data is ordered with respect to key
 * @returns {sortedData}
 */
export const sortDecreasing = (data, key) => {
  const compareFn = (k) => (a, b) => {
    if (a[k] < b[k]) return 1;
    if (a[k] > b[k]) return -1;
    return 0;
  };

  const arrayCopy = [...data];
  arrayCopy.sort(compareFn(key));
  return arrayCopy;
};

/**
 * @description sorts data by key in increasing order (A to Z)
 * @param data array of objects that shall be sorted
 * @param key string, data is ordered with respect to key
 * @returns {sortedData}
 */
export const sortIncreasing = (data, key) => sortDecreasing(data, key).reverse();

/**
 * @description generates data to display steps in a time series plot
 * @param data array of objects {time: ..., key: ..., ...}, MUST include key 'time' or 'hour' to work for older apps
 * @param key string, data is ordered with respect to key
 * @returns {plotData}
 */
export const getTimeValuePlot = (data, key) => {
  const plotData = [];
  const checkedData = Array.isArray(data) ? data : [];
  checkedData.map((d, i, a) => {
    if (i < data.length - 1) {
      if (d.time !== undefined) {
        plotData.push([d.time, d[key]], [a[i + 1].time, d[key]]);
      } else {
        plotData.push([d.hour, d[key]], [a[i + 1].hour, d[key]]);
      }

      return true;
    }

    return false;
  });
  return plotData;
};

/**
 * Get plotData for variable input form
 * @param name plotData of this plot gets returned
 * @param categories Array<string>
 * @returns {*}
 */
export const getVariableInputPlotData = (name, categories) => {
  const plotD = [];
  categories.map((r) => {
    plotD.push({
      name: `${r}`,
      data: getTimeValuePlot(name, r),
    });
    return true;
  });
  return plotD;
};

/**
 * Interpolate x,y Data
 * @param xValues array of current x values
 * @param yValues array of current y values
 * @param newXvalues array of new x values
 * @returns {*}
 */
export const interpolateArray = (xValues, yValues, newXvalues) =>
  newXvalues.map((newXvalue) => {
    if (xValues.length <= 1 || xValues.length !== yValues.length || newXvalues.length <= 1) return [null, null];
    const startIndex = xValues.findIndex((xValueCurrent) => xValueCurrent >= newXvalue);
    if (startIndex === undefined || startIndex === null) return [null, null];
    // no extrapolation
    if (startIndex === -1) return [xValues.slice(-1), yValues.slice(-1)];
    if (startIndex === 0) return [xValues[0], yValues[0]];
    const x0 = xValues[startIndex - 1];
    const x1 = xValues[startIndex];
    const y0 = yValues[startIndex - 1];
    const y1 = yValues[startIndex];
    const newYvalue = y0 + ((newXvalue - x0) / (x1 - x0)) * (y1 - y0);
    return [newXvalue, newYvalue];
  });

/**
 * Get data for visualisation of table data
 * @param tabledata data of table Array<Array<number>>
 * @param columnNames Array<string>
 * @param sortAscendingX sort the table data with ascending x value and add the id accordingly
 * @param isTimeseries determine if data is time dependent data
 * @param densifyData flag to activate adding data points
 * @param nrOfInterpolatedPoints number of points used for densifying the return data
 * @returns {*}
 */
export const getTablePlotData = (
  tableData,
  columnNames,
  sortAscendingX = false,
  isTimeseries = false,
  densifyData = false,
  nrOfInterpolatedPoints = 100
) => {
  const numSeries = columnNames.length - 1;
  const newSeries = [];
  for (let i = 0; i < numSeries; i += 1) {
    let data = [];

    if (sortAscendingX) {
      data = tableData.map((el) => ({
        x: el[0],
        y: el[i + 1],
      }));
      data.sort((a, b) => a.x - b.x);
      data = data.map((el, j) => ({ ...el, id: j }));
    } else {
      data = tableData.map((el, j) => ({
        x: isTimeseries ? el[0] * 1000 : el[0],
        y: el[i + 1],
        id: j,
      }));
      data.sort((a, b) => a.id - b.id);
    }

    // interpolate data to have more points in
    if (densifyData && data.length > 1) {
      const xValuesTable = data.map((el) => el.x);
      const yValuesTable = data.map((el) => el.y);

      const xLast = xValuesTable[data.length - 1];
      const xFirst = xValuesTable[0];
      const dxTotal = xLast - xFirst;
      const dx = Math.ceil((10 * dxTotal) / nrOfInterpolatedPoints) / 10;

      const xValuesIntp = [...Array(nrOfInterpolatedPoints).keys()].map((el) => el * dx);
      const xValuesAll = _.uniq([...xValuesIntp, ...xValuesTable].sort((a, b) => a - b));
      const newDenseData = interpolateArray(xValuesTable, yValuesTable, xValuesAll).map((el, elId) => ({
        x: el[0],
        y: el[1],
        id: elId + 1,
      }));
      newSeries.push({
        name: `${columnNames[i + 1]}`,
        key: `${columnNames[i + 1]}`,
        data: newDenseData,
      });
    } else {
      newSeries.push({
        name: `${columnNames[i + 1]}`,
        key: `${columnNames[i + 1]}`,
        data,
      });
    }
  }
  return newSeries;
};

/**
 * Get data for visualisation of table data
 * @param tabledata data of table Array<Array<number>>
 * @param columnNames Array<string>
 * @param sortAscendingX sort the table data with ascending x value and add the id accordingly
 * @returns {*}
 */
export const getTableTimeseriesPlotData = (tableData, columnNames) => {
  const numSeries = columnNames.length - 1;
  const newSeries = [];

  for (let i = 0; i < numSeries; i += 1) {
    let data = [];

    data = tableData.map((el, j) => ({
      x: el[0],
      y: el[i + 1],
      id: j,
    }));
    data.sort((a, b) => a.id - b.id);

    newSeries.push({
      name: `${columnNames[i + 1]}`,
      data,
    });
  }

  return newSeries;
};

/**
 * @description function to transform values of a nested array structure, as used for tables for example
 * @param nestedArrayData Array<Array<number>>
 * @param currentUnits Array<string>
 * @param requiredUnits Array<string>
 * @param structureContext
 */
export const transformNestedArrayUnits = (nestedArrayData, currentUnits, requiredUnits, structureContext) => {
  // very important to create a new array with new objects inside
  const newNestedArrayData = Array.isArray(nestedArrayData)
    ? nestedArrayData.map((x) => (Array.isArray(x) ? [...x] : x))
    : [];
  // conversion for each row and column
  nestedArrayData.forEach((row, rowId) => {
    if (Array.isArray(row)) {
      row.forEach((rowEl, colId) => {
        const currentUnit = currentUnits[colId];
        const requiredUnit = requiredUnits[colId];
        newNestedArrayData[rowId][colId] =
          currentUnit !== requiredUnit && typeof rowEl === 'number'
            ? unitConversion(structureContext.units, structureContext.quantities, currentUnit, rowEl, requiredUnit)
            : rowEl;
      });
    } else {
      const currentUnit = currentUnits[0];
      const requiredUnit = requiredUnits[0];
      newNestedArrayData[rowId] =
        currentUnit !== requiredUnit && typeof row === 'number'
          ? unitConversion(structureContext.units, structureContext.quantities, currentUnit, row, requiredUnit)
          : row;
    }
  });
  return newNestedArrayData;
};

/**
 * @description generates data for stacked plot, the time steps are 0.5 hours.
 * @param data array of objects {hour: ..., key: ..., ...}, MUST include key 'hour'
 * @param key string, data is ordered with respect to key
 * @param multiplier the key parameter is multiplied with, default: 1
 * @returns {plotData}
 */
export const getStackedPlotData = (data, key, multiplier = 1) => {
  const plotData = [];
  const reverseData = [...data].reverse();

  for (let i = 0; i < 24; i += 0.5) {
    const current = reverseData.find((e) => e.hour <= i);
    plotData.push([i, current[key] * multiplier]);
  }

  return plotData;
};

/**
 * @description Build options for react-select from array of profile objects
 * @param profiles the profiles for a variable input form
 * @param parameter (string) parameter that holds the variable parameter data
 * @return {*}
 */
export const getOptionsFromProfiles = (profiles, parameter) => {
  const options = [];
  profiles.map((l) => {
    options.push({
      value: l[parameter].profileID,
      label: l.label,
    });
    return true;
  });
  return options;
};

/**
 * @description returns true if an object is emtpty
 * @param obj object to be tested
 * @returns {*}
 */
export const isEmpty = (obj) => {
  // null and undefined are "empty"
  if (obj == null) return true;
  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;
  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;
  // Otherwise, does it have any properties of its own?
  return Object.keys(obj).length === 0;
};

/**
 * @description a function for deciding if a value is of type object, excluding arrays and null
 * @param value the value to be examined
 * @returns {*}
 */
export const checkIsObject = (item: unknown): item is Record<string, any> =>
  typeof item === 'object' && !Array.isArray(item) && item !== null;

/**
 * @description recursively searches a nested array/object structure for an object that is either the value to a key
 * equal to the search term or that has a value equal to the search term
 * @param haystack the nested array/object structure to be searched
 * @param needle the search term
 * @returns {*}
 */
// TODO improve typing of return type
export const breadthFirstSearchObject = (haystack: unknown, needle: string): any => {
  if (Array.isArray(haystack)) {
    let newHaystack: unknown[] = [];

    for (let index = 0; index < haystack.length; index += 1) {
      const element = haystack[index];
      // if element is an array, append it to newHaystack, its elements will be examined in the next recursion
      if (Array.isArray(element)) newHaystack = [...newHaystack, ...element];
      else if (checkIsObject(element)) {
        // if element's keys contain needle, return corresponing value
        if (Object.keys(element).includes(needle)) return element[needle];
        // if element's values contain needle, return whole element
        if (Object.values(element).includes(needle)) return element;
        // else append element's values to newHaystack, its elements will be examined in the next recursion
        newHaystack = [...newHaystack, ...Object.values(element)];
      } // if element is neither an array or object, it can not be or contain the object we are searching,
      // therefore it must not be included in newHaystack
    }

    // if nothing was returned and newHaystack is not empty, recurse
    if (newHaystack.length) return breadthFirstSearchObject(newHaystack, needle);
  }

  if (checkIsObject(haystack)) {
    // if haystack's keys contain needle, return corresponing value
    if (Object.keys(haystack).includes(needle)) return haystack[needle];
    // if haystack's values contain needle, return whole haystack
    if (Object.values(haystack).includes(needle)) return haystack;
    // else recurse with haystack's values
    return breadthFirstSearchObject(Object.values(haystack), needle);
  }

  return undefined;
};
export const transformStringToNumber = (stringValue) => {
  const numberValue = parseFloat(stringValue);
  return Number.isNaN(numberValue) ? stringValue : numberValue;
};
